<template>
  <div>
    <div class="bg max-w1200 mrauto mt47 mb51 pd20">
      <div class="pd40 bg-ffffff bor-E6E6E6">
        <div>
          <el-form ref="form3" :model="form3" label-width="11.25rem" label-position="right">
            <el-form-item label="区域：">
              <el-cascader
                v-model="form3.citytext"
                collapse-tags
                clearable
                :options="cityjson"
                :props="{ multiple: true }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="联系人：">
              <el-input v-model="form3.name" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input v-model="form3.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="上传手持身份证照片：">
              <el-upload
                class="avatar-uploader"
                action="https://pt.baipubang.com/api/other/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess5"
                name="media"
                :before-upload="beforeUpload5"
              >
                <img v-if="form3.imgUrl" :src="form3.imgUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="sbmitInfo3()"
                >提交审核</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { agents_sub, agents_apply_view } from "../../utils/api.js";
import cityjson from "../../assets/js/country-level3-data";
export default {
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        if (this.$route.query.apply_id) {
          this.apply_id = this.$route.query.apply_id;
          this.getInfo();
        }
      },
    },
  },
  data() {
    return {
      apply_id: "",
      partnerInfo: "",
      form3: {
        name: "",
        citytext: [], //城市
        province: "", //省
        city: "", //市
        city_area: "", //区
        phone: "",
        imgUrl: "",
        path: "",
      },
      cityjson: cityjson,
      uid: "",
    };
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
      if (this.$route.query.apply_id) {
        this.apply_id = this.$route.query.apply_id;
        this.getInfo();
      }
    } else {
      this.$message.error("还未登录，请登录后再使用");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
          query: {navid: 10,},
        });
        // let routeData = this.$router.resolve({
        //   path: "/login",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }, 1500);
    }
  },
  methods: {
    sbmitInfo3() {
      let that = this;
      let citytext = "";
      if (that.form3.citytext.length && that.form3.citytext) {
        let arr = [];
        that.form3.citytext.forEach((item) => {
          arr.push(item[0] + "-" + item[1] + "-" + item[2]);
        });
        citytext = arr.join("|");
      }
      agents_sub({
        uid: that.uid,
        area_list: citytext,
        name: that.form3.name,
        phone: that.form3.phone,
        card_img: that.form3.path,
      })
        .then((res) => {
          console.log(res);
          if (res.code != 200) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success(res.msg);
          setTimeout(() => {
            // this.$router.push({
            //   path: "/indexer",
            //   query: { navid: 1 },
            // });
            let routeData = this.$router.resolve({
              path: "/indexer",
              query: { navid: 1 },
            });
            window.open(routeData.href, "_blank");
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
        });
    },
    // 证件
    beforeUpload5(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess5(res, file) {
      console.log(res, file);
      this.form3.path = res.data.media;
      this.form3.imgUrl = res.data.path_media;
    },
    getInfo() {
      let that = this;
      agents_apply_view({
        apply_id: that.apply_id,
      })
        .then((res) => {
          console.log(res);
          this.form3.imgUrl = res.data.card_img;
          this.form3.path = res.data.card_path;
          this.form3.name = res.data.name;
          this.form3.phone = res.data.phone;
          let add = [];
          res.data.area.forEach((item) => {
            let arr = [];
            arr.push(item.province);
            arr.push(item.city);
            arr.push(item.city_area);
            add.push(arr);
          });
          console.log(add);
          this.form3.citytext = add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }

.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}

.avatar {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}
</style>
